import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, Navigate, useParams } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { collection, doc, getDoc, onSnapshot, query, orderBy, limit, startAfter, limitToLast, endBefore } from "firebase/firestore"
import { Row, Col, Modal, Table, Button } from 'react-bootstrap';
import TripDetails from './trip_details'


// Auth functionality: https://blog.logrocket.com/user-authentication-firebase-react-apps/
const Vehicle = () => {

	const [user] = useAuthState(auth);

	const [vehicle, setVehicle] = useState();
	const [trips, setTrips] = useState();
	const [modalShow, setModalShow] = useState(false);
	const [modalMapRoute, setModalMapRoute] = useState(false);

	// Pagination state variables
	const [lastVisible, setLastVisible] = useState();
	const [firstVisible, setFirstVisible] = useState();
	const [limitPerPage, setLimitPerPage] = useState( 10 );
	const [pageNumber, setPageNumber] = useState(0);
	const [isLastPage, setIsLastPage] = useState(false);

	let vin = useParams().vin;

	// Get vehicle by vin
	useEffect(() => {
		if (user) {
			const unsubscribe = onSnapshot(doc(db, "vehicles", vin), (snapshot) => {
				setVehicle({ id: snapshot.id, ...snapshot.data() } );
			});
			return () => unsubscribe();
		}
	}
	, [user]);

	// get trips of vehicle
	useEffect(() => {
		if (user) {
			const unsubscribe = onSnapshot( query(collection(db, `vehicles/${vin}/trips`), orderBy('start', 'desc'), limit( limitPerPage )), (snapshot) => {
				if(snapshot.size) {
					setTrips(snapshot.docs.map(
						doc => (doc.id!='openTrip' && { id:doc.id, ...doc.data() })
					));

					// Get the first & last visible document
					setFirstVisible( snapshot.docs[0] );
					setLastVisible( snapshot.docs[ snapshot.size-1 ] );
					setPageNumber( 1 );

					if(snapshot.size < limitPerPage) {
						setIsLastPage(true);
					}
				} else {
					setIsLastPage(true);
				}
			});
			return () => unsubscribe();
		}
	}
	, [user]);

	const handleNextPage = () => {
		const unsubscribe = onSnapshot(query(collection(db, `vehicles/${vin}/trips`), orderBy('start', 'desc'), limit( limitPerPage ), startAfter(lastVisible)), (snapshot) => {
			if(snapshot.size) {
				setTrips(snapshot.docs.map(
					doc => (doc.id!='openTrip' && { id:doc.id, ...doc.data() })
				));

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( pageNumber+1 );

				if(snapshot.size < limitPerPage) {
					setIsLastPage(true);
				}
			} else {
				setIsLastPage(true);
			}
		});
		return () => unsubscribe();
	}

	const handlePreviousPage = () => {
		if (pageNumber > 1) {
			const unsubscribe = onSnapshot(query(collection(db, `vehicles/${vin}/trips`), orderBy('start', 'desc'), limitToLast( limitPerPage ), endBefore(firstVisible)), (snapshot) => {
				setTrips(snapshot.docs.map(
					doc => (doc.id!='openTrip' && { id:doc.id, ...doc.data() })
				));

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( pageNumber-1 );
				// TODO: check how can I remove this line
				setIsLastPage(false);
			});
			return () => unsubscribe();
		}
	}

	const MyVerticallyCenteredModal = (props) => {
		// passing data to the modal: https://www.freakyjolly.com/react-bootstrap-4-modals-passing-data-from-component-tutorial/
		// react modal: https://react-bootstrap.github.io/components/modal/
		return (
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">Vehicle CAN Data</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.show && Object.keys(props.show).map(function(k) {
						return <div key={k}><b>{k}:</b>{typeof props.show[k] == 'string' && props.show[k]}</div>;
					})}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={props.onHide}>Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	const showCustomer = (customerReferenceId) => {
		// Get the customer data from the database
		const unsubscribe = onSnapshot(doc(db, `customers/${customerReferenceId.id}`), (snapshot) => {
			console.log(snapshot.data());
			setModalShow(snapshot.data());
		}
		);
		return () => unsubscribe();
	}


	// Render the dashboard
	return (
		<Row>
			<h3>Vehicle Detail:</h3>
			<Table bordered={true}>
				<thead>
					<tr>
						<th>Customer Name</th>
						<th>Model</th>
						<th>VIN Number</th>
						<th>Color</th>
						<th>Milage</th>
						<th>Dealer</th>
						<th>Registeration Date</th>
						<th>IMEI</th>
						<th>ICCID</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{vehicle && (
						<tr key={vehicle.id}>
							<td><Button onClick={() => showCustomer(vehicle.customer_id)} to={`/customers/${vehicle.customer_id?.id}`}>{vehicle.name}</Button></td>
							<td><Link to={`/vehicles/${vehicle.id}`}>{vehicle.model}</Link></td>
							<td>{vehicle.id}</td>
							<td>{vehicle.color}</td>
							<td>{vehicle.milage}</td>
							<td>{vehicle.dealer}</td>
							<td>{vehicle.registered_at && new Date(vehicle.registered_at.seconds * 1000).toLocaleString()}</td>
							<td>-</td>
							<td>-</td>
							<td>
								<Link to={`/map/${vehicle.id}`}>Track</Link> - 
								<Button onClick={() => setModalShow(vehicle.CAN_data)} disabled={!vehicle.CAN_data && true}>CAN Data</Button> - 
								{ vehicle.tickets > 0
									? <Link to={`/tickets/${vehicle.id}`}>{vehicle.tickets} Tickets</Link>
									: `No Ticket`
								}
							</td>
						</tr>
					)}
				</tbody>
			</Table>

			<h3>Trips <i style={{fontSize:'14px'}}>(page {pageNumber})</i></h3>
			<Table striped bordered={true} hover={true}>
				<thead>
					<tr>
						<th>Date</th>
						<th>Mileage</th>
						<th>Duration</th>
						<th>Burned Gas</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{trips && trips.length>0 ? trips.map((trip) => ( trip && 
						<tr key={trip.id}>
							<td>{trip.start && new Date(trip.start.seconds * 1000).toLocaleString()}</td>
							<td>{trip.mileage}</td>
							<td>{trip.duration && trip.duration}{!trip.duration &&  Math.round((trip.end.seconds - trip.start.seconds) / 60) } mins</td>
							<td>-</td>
							<td><Button onClick={() => setModalMapRoute(trip.route)} to={`/vehicles/{vehicle.id}/trips/{trip.id}`}>Show Route</Button></td>
						</tr>
					))
					: <tr><th colSpan={5} style={{padding:"30px"}}>There are no trips recorded for this vehicle.</th></tr>}
				</tbody>
			</Table>
			<div className='pagination'>
				<Button size="md" onClick={handlePreviousPage} disabled={pageNumber>1 ? false : true}>Previous</Button>
				<span>Page {pageNumber}</span>
				<Button size="md" onClick={handleNextPage} disabled={isLastPage ? true : false}>Next</Button>
			</div>

			<MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
			<TripDetails show={modalMapRoute} onHide={() => setModalMapRoute(false)} />
		</Row>
	);
}

export default Vehicle;