import React from 'react'
import { Container } from 'react-bootstrap'

export default function CenteredContainer({ children }) {
	return (
		<Container
			className="d-flex align-items-center justify-content-center"
			style={{ minHeight: "90vh" }}>
				<div className="w-100" style={{maxWidth:"400px"}}>
					<h2 style={{textAlign:'center', marginBottom:'20px'}}><img src="/car.png" width='160px' /><br/> Massimo UTV Fleet</h2>
					{children}
				</div>
		</Container>
	)
}