import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from 'react-router-dom';
import { auth, db } from './../firebase';
import { collection, doc, onSnapshot, query, orderBy, limit, limitToLast, startAfter, endBefore } from "firebase/firestore"
import { Row, Col, Modal, Table, Button } from 'react-bootstrap';

// Auth functionality: https://blog.logrocket.com/user-authentication-firebase-react-apps/
const Vehicles = () => {

	const [user] = useAuthState(auth);

	const [vehicles, setVehicles] = useState();
	const [modalShow, setModalShow] = useState(false);
	
	// Pagination state variables
	const [lastVisible, setLastVisible] = useState();
	const [firstVisible, setFirstVisible] = useState();
	const [limitPerPage, setLimitPerPage] = useState( 10 );
	const [pageNumber, setPageNumber] = useState(0);
	const [isLastPage, setIsLastPage] = useState(false);

	// Get all vehicles from the database by useEffect
	useEffect(() => {
		if (user) {
			const unsubscribe = onSnapshot(query(collection(db, "vehicles"), orderBy('registered_at', 'desc'), limit( limitPerPage )), (snapshot) => {
				if(snapshot.size) {
					setVehicles(snapshot.docs.map(
						doc => ({ id:doc.id, ...doc.data() })
					));

					// Get the first & last visible document
					setFirstVisible( snapshot.docs[0] );
					setLastVisible( snapshot.docs[ snapshot.size-1 ] );
					setPageNumber( 1 );

					if(snapshot.size < limitPerPage) {
						setIsLastPage(true);
					}
				} else {
					setIsLastPage(true);
				}
			});
			return () => unsubscribe();
		}
	}
	, [user]);

	const handleNextPage = () => {
		const unsubscribe = onSnapshot(query(collection(db, "vehicles"), orderBy('registered_at', 'desc'), limit( limitPerPage ), startAfter(lastVisible)), (snapshot) => {
			if(snapshot.size) {
				setVehicles(snapshot.docs.map(
					doc => ({ id:doc.id, ...doc.data() })
				));

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( pageNumber+1 );

				if(snapshot.size < limitPerPage) {
					setIsLastPage(true);
				}
			} else {
				setIsLastPage(true);
			}
		});
		return () => unsubscribe();
	}

	const handlePreviousPage = () => {
		if (pageNumber > 1) {
			const unsubscribe = onSnapshot(query(collection(db, "vehicles"), orderBy('registered_at', 'desc'), limitToLast( limitPerPage ), endBefore(firstVisible)), (snapshot) => {
				setVehicles(snapshot.docs.map(
					doc => ({ id:doc.id, ...doc.data() })
				));

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( pageNumber-1 );
				// TODO: check how can I remove this line
				setIsLastPage(false);
			});
			return () => unsubscribe();
		}
	}



	const MyVerticallyCenteredModal = (props) => {
		// passing data to the modal: https://www.freakyjolly.com/react-bootstrap-4-modals-passing-data-from-component-tutorial/
		// react modal: https://react-bootstrap.github.io/components/modal/
		return (
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">Vehicle CAN Data</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.show && Object.keys(props.show).map(function(k) {
						return <div key={k}><b>{k}:</b>{typeof props.show[k] == 'string' && props.show[k]}</div>;
					})}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={props.onHide}>Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	const showCustomer = (customerReferenceId) => {
		// Get the customer data from the database
		const unsubscribe = onSnapshot(doc(db, `customers/${customerReferenceId.id}`), (snapshot) => {
			console.log(snapshot.data());
			setModalShow(snapshot.data());
		}
		);
		return () => unsubscribe();
	}


	// Render the dashboard
	return (
		<>
			<h3>Vehicles <i style={{fontSize:'14px'}}>(page {pageNumber})</i></h3>
			<Table striped bordered={true} hover={true}>
				<thead>
					<tr>
						<th>Model</th>
						<th>Color</th>
						<th>Customer Name</th>
						<th>Dealer</th>
						<th>Registeration Date</th>
						<th>Milage</th>
						<th>Trips</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{vehicles && vehicles.length>0 ? vehicles.map(vehicle => (
						<tr key={vehicle.id}>
							<td><Link to={`/vehicles/${vehicle.id}`}>{vehicle.model}</Link></td>
							<td>{vehicle.color}</td>
							<td><a href={``} onClick={() => showCustomer(vehicle.customer_id)} to={`/customers/${vehicle.customer_id?.id}`}>{vehicle.firstname} {vehicle.lastname}</a></td>
							<td>{vehicle.dealer}</td>
							<td>{vehicle.registered_at && new Date(vehicle.registered_at.seconds * 1000).toLocaleString([], {dateStyle: 'short'})}</td>
							<td>{vehicle.milage}</td>
							<td>{vehicle.trips ? <Link to={`/vehicles/${vehicle.id}`}>{vehicle.trips} trips</Link> : `-`}</td>
							<td>
								<Link to={`/map/${vehicle.id}`}>Track</Link> - 
								<Link to='#' onClick={() => setModalShow(vehicle.CAN_data)} disabled={!vehicle.CAN_data && true}>CAN Data</Link> - 
								{ vehicle.tickets > 0
									? <Link to={`/tickets/${vehicle.id}`}>{vehicle.tickets} Tickets</Link>
									: `No Ticket`
								}
							</td>
						</tr>
					))
					: <tr><th colSpan="7" style={{padding:"30px"}}>There is no vehicle data.</th></tr>}
				</tbody>
			</Table>
			<div className='pagination'>
				<Button size="md" onClick={handlePreviousPage} disabled={pageNumber>1 ? false : true}>Previous</Button>
				<span>Page {pageNumber}</span>
				<Button size="md" onClick={handleNextPage} disabled={isLastPage ? true : false}>Next</Button>
			</div>

			<MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
		</>
	);
}



// 	const fetchUserName = async () => {
// 		try {
// 		  const q = query(collection(db, "users"), where("uid", "==", user?.uid));
// 		  const doc = await getDocs(q);
// 		  const data = doc.docs[0].data();
// 		//   setName(data.name);
// 		} catch (err) {
// 		  console.error(err);
// 		  alert("An error occured while fetching user data");
// 		}
// 	};

// 	return (
// 		<div className="container">
// 			<div>
// 				Logged in as {user?.email}
// 				<button onClick={logout}>Logout</button>
// 			</div>
// 			<Sidebar />
// 			<div className="panel panel-default">
// 				<div className="panel-heading">
// 					<h3 className="panel-title">
// 						Vehicles List
// 					</h3>
// 				</div>
// 				<div className="panel-body">
// 					<h4><Link to="/map">Live Map</Link></h4>
// 					<h4><Link to="/create">Add Vehicle</Link></h4>
// 					<table border="1" cellSpacing={0} cellPadding={10}>
// 						<thead>
// 							<tr>
// 								<th>Customer Name</th>
// 								<th>Model</th>
// 								<th>Color</th>
// 								<th>Milage</th>
// 								<th>Dealer</th>
// 								<th>Registration Date</th>
// 								<th>Actions</th>
// 							</tr>
// 						</thead>
// 						<tbody>
// 							{vehicles && vehicles.map(vehicle =>
// 								<tr key={vehicle.key}>
// 									<td><Link to={`/customer/${vehicle.customer_id}`}>{vehicle.firstname} {vehicle.lastname}</Link></td>
// 									<td><Link to={`/vehicle/${vehicle.key}`}>{vehicle.model}</Link></td>
// 									<td>{vehicle.color}</td>
// 									<td>{vehicle.milage}</td>
// 									<td>{vehicle.dealer}</td>
// 									<td>{new Date(vehicle.registered_at.seconds * 1000).toLocaleString()}</td>
// 									<td><Link to={`/map/${vehicle.key}`}>Track</Link> - <Link to={`/vehicles/${vehicle.key}`}>CAN Data</Link> - { vehicle.tickets > 0
// 										? <Link to={`/tickets/${vehicle.key}`}>{vehicle.tickets} Tickets</Link>
// 										: `No Ticket`
// 										}</td>
// 								</tr>
// 							)}
// 						</tbody>
// 					</table>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

export default Vehicles;