import React, { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from 'react-router-dom';
import { auth, db } from './../firebase';
import { collection, query, onSnapshot, orderBy, limit, startAfter, limitToLast, endBefore } from "firebase/firestore"
import { Row, Table, Button } from 'react-bootstrap';

const Customers = () => {
	
	const [user] = useAuthState(auth);

	const [customers, setCustomers] = useState()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(true)

	// Pagination state variables
	const [lastVisible, setLastVisible] = useState();
	const [firstVisible, setFirstVisible] = useState();
	const [limitPerPage, setLimitPerPage] = useState( 10 );
	const [pageNumber, setPageNumber] = useState(0);
	const [isLastPage, setIsLastPage] = useState(false);


	useEffect(() => {
		if (user) {
			const unsubscribe = onSnapshot(query( collection(db, "customers"), orderBy('created_at', 'desc'), limit( limitPerPage ) ), (snapshot) => {
				if(snapshot.size) {
					setCustomers(snapshot.docs.map(
						doc => ({ id:doc.id, ...doc.data() })
					));

					// Get the first & last visible document
					setFirstVisible( snapshot.docs[0] );
					setLastVisible( snapshot.docs[ snapshot.size-1 ] );
					setPageNumber( 1 );

					if(snapshot.size < limitPerPage) {
						setIsLastPage(true);
					}
				} else {
					setIsLastPage(true);
				}
			});
			return () => unsubscribe()
		}
	}
	, [user])

	const handleNextPage = () => {
		const unsubscribe = onSnapshot(query(collection(db, "customers"), orderBy('created_at', 'desc'), limit( limitPerPage ), startAfter(lastVisible)), (snapshot) => {
			if(snapshot.size) {
				setCustomers(snapshot.docs.map(
					doc => (doc.id!='openTrip' && { id:doc.id, ...doc.data() })
				));

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( pageNumber+1 );

				if(snapshot.size < limitPerPage) {
					setIsLastPage(true);
				}
			} else {
				setIsLastPage(true);
			}
		});
		return () => unsubscribe();
	}

	const handlePreviousPage = () => {
		if (pageNumber > 1) {
			const unsubscribe = onSnapshot(query(collection(db, "customers"), orderBy('created_at', 'desc'), limitToLast( limitPerPage ), endBefore(firstVisible)), (snapshot) => {
				setCustomers(snapshot.docs.map(
					doc => (doc.id!='openTrip' && { id:doc.id, ...doc.data() })
				));

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( pageNumber-1 );
				// TODO: check how can I remove this line
				setIsLastPage(false);
			});
			return () => unsubscribe();
		}
	}


	return (
		<div>
			<h3>Customers <i style={{fontSize:'14px'}}>(page {pageNumber})</i></h3>
			<Table striped bordered={true} hover={true}>
				<thead>
					<tr>
						<th>Customer Name</th>
						<th>Phone</th>
						<th>Address</th>
						<th>Email</th>
						<th>Vehicles Owned</th>
					</tr>
				</thead>
				<tbody>
					{customers && customers.length>0 ? customers.map(doc =>
						<tr key={doc.id}>
							<td><Link to={`/customers/${doc.id}`}>{doc.name}</Link></td>
							<td>{doc.phone}</td>
							<td>{doc.address}</td>
							<td>{doc.email}</td>
							<td>{doc.vehicles.map( (v, k) => (
									<span key={k}>{ k > 0 && ` - ` }<Link to={`/vehicles/${v.vin}`}>{v.model}</Link></span>
								))}</td>
						</tr>
					) : <tr><th colSpan={5} style={{padding:"30px"}}>There is no customers data.</th></tr>}
				</tbody>
			</Table>
			<div className='pagination'>
				<Button size="md" onClick={handlePreviousPage} disabled={pageNumber>1 ? false : true}>Previous</Button>
				<span>Page {pageNumber}</span>
				<Button size="md" onClick={handleNextPage} disabled={isLastPage ? true : false}>Next</Button>
			</div>
		</div>
	)
}
export default Customers;