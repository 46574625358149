import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Speedometer, Map, Truck, People, TicketPerforated, FiletypeCsv, PersonCircle } from 'react-bootstrap-icons';

const Sidebar = () => {
	return (
		<nav className='menu'>
			<NavLink to="/"><Speedometer /> Dashboard</NavLink>
			<NavLink to="/map"><Map /> Live Map</NavLink>
			<NavLink to="/mapCCID"><Map /> Map of Simcards</NavLink>
			<NavLink to="/vehicles"><Truck /> Vehicles List</NavLink>
			<NavLink to="/customers"><People /> Customers List</NavLink>
			<NavLink to="/tickets"><TicketPerforated /> Ticket Management</NavLink>
			<NavLink to="/import"><FiletypeCsv /> Import Data</NavLink>
			<NavLink to="/users"><PersonCircle /> Users</NavLink>
		</nav>
	)
}

export default Sidebar;
