import React from "react"
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import Template from "../../components/Template"

// Private Route in react-router v6:
// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5

// export default function PrivateOutlet () {
//   const { currentUser } = useAuth()
//   return currentUser ? <Outlet /> : <Navigate to="/login" />;
// }

export default function PrivateRoute({ children, ...rest }) {
  const { currentUser } = useAuth()
  return currentUser ? <Template>{children}</Template> : <Navigate to="/login" />;
}