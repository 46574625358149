import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useParams } from "react-router-dom";
import { auth, db } from "../../firebase";
import { collection, onSnapshot, getDoc, doc } from "firebase/firestore";
import { Row, Col, Form, Spinner, Button } from "react-bootstrap";
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';
import Points from "./Points";
import { ArrowLeftSquare, Voicemail, Phone, Mailbox } from "react-bootstrap-icons";
import  { geohashForLocation, geohashQueryBounds, distanceBetween } from "geofire-common";
import { debounce } from "../../utils/functions";
import VehicleDetailsCard from "./vehicleDetailsCard";


const Live = () => {
	console.log('live.js')
	// default map center is the Massimo HQ Office location in Garland, TX
	const [center, setCenter] = useState({ lat: 32.8959058, lng: -96.6754921 });
	const [vehicle, setVehicle] = useState(null);
	const [showSideBar, setShowSideBar] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const params = useParams()
	const [mapRef, setMapRef] = useState(null);
	const [queryBounds, setQueryBounds] = useState(null);
	const [alaki, setAlaki] = useState(null);


	// When map ref is set (means map is loaded), get vehicle details
	useEffect(() => {
		console.log('useEffect by mapRef');
		// if vin param is passed
		if ( params.vin && vehicle === null ) {
			loadVehicleDetails(params.vin);
		}
	}, [mapRef]);


	// get vehicle details from database by VIN
	const loadVehicleDetails = async (vin, pan) => {
		console.log('loadVehicleDetails called!');
		setVehicle(null);
		setShowSideBar(true);
		setLoading(true);
		setError(null);

		const docSnap = await getDoc( doc(db, `vehicles`, vin) )
		if( docSnap.exists() ) {

			// center map with smooth transition on vehicle location
			if( mapRef && pan ) {
				mapRef.panTo({ lat: docSnap.data().location.location.latitude, lng: docSnap.data().location.location.longitude })
				mapRef.panBy(0, -50);
			} else {
				setMapCenter({ lat: docSnap.data().location.location.latitude, lng: docSnap.data().location.location.longitude });
			}

			setVehicle({ id:docSnap.id, ...docSnap.data() });
			setLoading(false);

		} else {
			setError('Vehicle not found');
		}
	}

	const handleShowMapFilters = () => {
		setShowSideBar('renderFilters');
		console.log('showSideBar:', showSideBar);
	}

	const handleClose = () => {
		setShowSideBar(false);
		// setVehicle(null);
	}

	const handleBoundsChanged = () => {
		console.log('%chandleBoundsChanged called!', 'color:red', center);
		if( mapRef ) {

			// const bounds = geohashQueryBounds([
			// 	mapRef.getCenter().lat(),
			// 	mapRef.getCenter().lng()
			// ], 1 * 1000);

			setQueryBounds( getBoundsRadius() );
			setMapCenter( {
				lat: mapRef.getCenter().lat(),
				lng: mapRef.getCenter().lng()
			} );
		}
	}

	// setCenter if lat or lng changed
	const setMapCenter = (c) => {
		if( c.lat!=center.lat || c.lng!=center.lng )
			setCenter(c);
	}

	const handleMapLoaded = (map) => {
		console.log('%chandleMapLoaded called!', 'color:green');
		
		// Limit Zoom Level
		map.setOptions({
			minZoom: 10,
			maxZoom: 16
		});
		
		setMapRef(map);
	}

	// Source for this function:
	// https://stackoverflow.com/questions/3525670/radius-of-viewable-region-in-google-maps-v3
	function getBoundsRadius(){
		let bounds = mapRef.getBounds();
		if(!bounds) return 2000;

		// r = radius of the earth in km
		var r = 6378.8
		// degrees to radians (divide by 57.2958)
		var ne_lat = bounds.getNorthEast().lat() / 57.2958
		var ne_lng = bounds.getNorthEast().lng() / 57.2958
		var c_lat = bounds.getCenter().lat() / 57.2958
		var c_lng = bounds.getCenter().lng() / 57.2958
		// distance = circle radius from center to Northeast corner of bounds
		var r_km = r * Math.acos(
			Math.sin(c_lat) * Math.sin(ne_lat) + 
			Math.cos(c_lat) * Math.cos(ne_lat) * Math.cos(ne_lng - c_lng)
		)

		// TODO: should show markers clustered / server clustered for upper zoom levels
		// or just simply limit zoom out and add search city ability to the map.

		// No radius bigger than 50km
		r_km = r_km > 60 ? 60 : r_km;

		console.log('Radius:', Math.round(r_km*0.8 * 1000));
		return Math.round(r_km*0.8 * 1000) // radius in meters
	}



	const renderMapFilters = () => {
		console.log('renderMapFilters called')
		return (
				<div className="body">
					<Form.Group as={Row} className="mb-3" controlId="city">
						<Form.Label column sm="2">
							City
						</Form.Label>
						<Col sm="10">
							<Form.Control type="text" placeholder="City Name ..." />
						</Col>
					</Form.Group>
					Filters: City, Max Mileage, Min Mileage, Gear Status, Vin Number, Vehicle Model
				</div>
		);
	}


	const [autocomplete, setAutocomplete] = useState(null);

	const handleAutocomplete = (autocomplete) => {
		console.log('autocomplete: ', autocomplete)
	
		setAutocomplete(autocomplete);
	}
  
	const handlePlaceChanged = () => {
		if (autocomplete !== null) {
			let place = autocomplete.getPlace();

			// Place not found
			if (!place.geometry || !place.geometry.location)
				return;

			if ( place.geometry.viewport ) {
				mapRef.fitBounds( place.geometry.viewport );
			} else {
				mapRef.setCenter( place.geometry.location );
				mapRef.setZoom(16);
			}

		} else {
			console.log('Autocomplete is not loaded yet!')
		}
	}

	return (
		<div style={{display:'flex', flexDirection:'row', justifyItems:'stretch', margin:'-20px'}}>

			{showSideBar &&
				<div className="mapSideCard">

					<div className="bar">
						<ArrowLeftSquare className="close" size={30} onClick={handleClose} />
					</div>

					{loading && <div className="loading"><div>
							<Spinner animation="border" />
							<p>Loading Vehicle Details</p>
						</div></div>}

					{vehicle && <VehicleDetailsCard vehicle={vehicle} />}
					
					{showSideBar === 'renderFilters' && renderMapFilters()}
				</div>
			}

			<div style={{flexBasis:0, flexGrow:999, display:'flex', flexDirection:'column', alignItems:'strech', height:'100%'}}>
				<LoadScript
					googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
					libraries={["places"]}
					>
					<GoogleMap
						mapContainerStyle={{
							position: 'relative',
							width: '100%',
							height: '80vh'
						}}
						center={ center }
						zoom={14}
						onLoad={map => handleMapLoaded(map)}
						onBoundsChanged={ debounce(handleBoundsChanged, 700) }
						// onDragEnd={ debounce(handleBoundsChanged, 700) }
						// onZoomChanged={ debounce(handleBoundsChanged, 700) }
					>
						<Autocomplete
							onLoad={handleAutocomplete}
							onPlaceChanged={handlePlaceChanged}
						>
							<input
							type="text"
							placeholder="Customized your placeholder"
							style={{
								boxSizing: `border-box`,
								border: `1px solid transparent`,
								width: `240px`,
								height: `32px`,
								padding: `0 12px`,
								borderRadius: `3px`,
								boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
								fontSize: `14px`,
								outline: `none`,
								textOverflow: `ellipses`,
								position: "absolute",
								left: "50%",
								marginLeft: "-120px"
							}}
							/>	
						</Autocomplete>

						{(mapRef && queryBounds) && <Points
							center={ center }
							boundsRadiusInMeter={queryBounds}
							loadVehicleDetails={ loadVehicleDetails }
						/>}
					</GoogleMap>
				</LoadScript>
			</div>
		</div>
	);
}


export default Live