import React, { useState, useEffect } from "react";
import { db } from './../firebase';
import { doc, setDoc } from "firebase/firestore"
import Papa from "papaparse";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";




const ImportData = () => {
	// import data from cvs file to firebase
	const [file, setFile] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [rows, setRows] = useState(0);

	const handleChange = (e) => {
		setFile(e.target.files[0]);
		setLoading(false);
		setError(false);
	}

	const parseFile = file => {
		Papa.parse(file, {
			preview: 1,
			complete: function(results) {
				// check if all keys are present
				if ( results.data[0].length === 3 && results.data[0].includes('vin') && results.data[0].includes('model') && results.data[0].includes('color') ) {
					let rowsCount = 0;
					Papa.parse(file, {
						header: true,
						step: async function(row, parser){
							parser.pause();
			
							// TODO: Validate the data values too!
							if (('vin' in row.data) && ('model' in row.data) && ('color' in row.data)) {
								// TODO: create doc if VIN not exists, we don't want to update existing doc
								await setDoc(doc(db, "vehicles", row.data.vin), {
									model: row.data.model,
									color: row.data.color,
									status: "Imported"
								}).then(() => {
									parser.resume();
									setRows( ++rowsCount );
								}).catch(err => {
									console.log(err);
									parser.resume();
								});
							}
						  },
					});
				} else {
					setError('Invalid CSV file format.');
				}
			}
		})
	};

	const handleUpload = () => {
		if(file) {
			setLoading(true);
			parseFile(file);
			setFile(null);
		}
	}


	// useEffect(() => {
	// 	if (success) {
	// 		setSuccess(false);
	// 		setFile(null);
	// 	}
	// }
	// , [success]);

	return (
		<Row>
			<Col xs="auto">
				<Form.Group controlId="formFile" className="mb-2">
					<Form.Label>CSV File:</Form.Label>
					<Form.Control type="file" accept=".csv" onChange={handleChange} />
					<Form.Text className="text-muted">VIN import data format: [ vin, model, color ]</Form.Text>
				</Form.Group>
				<Button variant="primary" type="submit" onClick={handleUpload}>Submit</Button>
				{loading && <p>{rows} row{rows>1 && `s`} uploaded.</p>}
				{error && <Alert variant="danger">{error}</Alert>}
			</Col>
		</Row>
	);
}

export default ImportData;