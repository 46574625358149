import React, { useState, useEffect } from 'react';
import { auth, db } from "./../../firebase";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { InfoWindow } from '@react-google-maps/api';
import { Spinner, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const InfoCard = ({ onCloseClick, position, vehicle, loadVehicleDetails }) => {

	const gearList = {
		p: 'Parking',
		r: 'Reverse',
		n: 'Neutral',
		d: 'Driving'
	}

	return (
		<InfoWindow onCloseClick={onCloseClick} position={position}>
			<div style={{padding:'5px 10px', lineHeight:'20px'}}>
				VIN: <b style={{fontSize:'12px', fontWeight:500}}>{vehicle.id}</b><br/>
				Gear: <b>On {gearList[vehicle.gear]}</b><br/>
				Speed: <b>{vehicle.speed.toFixed(2)} MPH</b><br/>
				Mileage: <b>{vehicle.mileage} Miles</b><br/>
				Direction: <b>{vehicle.direction.toFixed()} Degree</b><br/>
				Location: <b>{vehicle.location.latitude.toFixed(5)}, {vehicle.location.longitude.toFixed(5)}</b><br/>
				<div style={{marginTop:'10px', textAlign:'center'}}>
					<Button onClick={() => loadVehicleDetails(vehicle.id, true)} size='sm'>More Information</Button>
				</div>
			</div>
		</InfoWindow>
	)
}

export default InfoCard;