import React, { useState } from "react"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
 } from 'chart.js';
 import { Line } from 'react-chartjs-2';


export default function TripsChart() {
	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Filler,
		Legend
	);

	const options = {
		responsive: true,
		plugins: {
		legend: {
			position: 'bottom',
		},
		title: {
			display: true,
			text: 'Trips count in last 30 days',
		},
		},
	};

	const data = {
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		datasets: [
		{
			fill: true,
			label: 'Trips Count',
			data: [26, 38, 34, 44, 56, 78, 108, 128, 146, 189],
			borderColor: 'rgb(53, 162, 235)',
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
			tension:.4,
			pointStyle: 'circle',
			pointRadius: 5,
			pointHoverRadius: 10
		},
		],
	};
	
	return (
		<Line options={options} data={data} />
	)
}