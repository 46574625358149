import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from '../../firebase';
import { collection, onSnapshot, query, where, doc, orderBy } from "firebase/firestore"
import { Row } from "react-bootstrap";
import Admin from './admin'
import Support from './support'

const Tickets = () => {

	const [user] = useAuthState(auth);
	const [userDetails, setUserDetails] = useState();

	const status = []
	status[process.env.REACT_APP_TICKET_STATUS_NEW] = 'New'
	status[process.env.REACT_APP_TICKET_STATUS_IN_PROGRESS] = 'In Progress'
	status[process.env.REACT_APP_TICKET_STATUS_COMPLETED] = 'Completed'
	status[process.env.REACT_APP_TICKET_STATUS_ARCHIVED] = 'Archived'

	useEffect(() => {
		const unsubscribe = onSnapshot(doc(db, "users", user.uid), (snapshot) => {
			setUserDetails( snapshot.data() );
		});
		return () => unsubscribe();
	}, [user])


	return (
		<Row>
			{userDetails && userDetails.rule=='admin'
				? <Admin />
				: <Support />
			}
		</Row>
	)
}

export default Tickets;