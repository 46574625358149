import React, { useState } from "react"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
 } from 'chart.js';
 import { Line } from 'react-chartjs-2';


export default function TicketsChart() {
	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Filler,
		Legend
	);

	const options = {
		responsive: true,
		plugins: {
		legend: {
			position: 'bottom',
		},
		title: {
			display: true,
			text: 'Submitted Tickets count in last 30 days',
		},
		},
	};

	const data = {
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		datasets: [
			{
				fill: true,
				label: 'Submitted Tickets Count',
				data: [11, 12, 12, 14, 16, 19, 25, 35, 40, 50],
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
				tension:.4,
				pointStyle: 'circle',
				pointRadius: 5,
				pointHoverRadius: 10
			},
			{
				fill: true,
				label: 'Responded Tickets Count',
				data: [0, 1, 1, 3, 4, 7, 12, 18, 27, 35],
				borderColor: 'rgb(53, 132, 135)',
				backgroundColor: 'rgba(53, 132, 135, 0.5)',
				tension:.4
			}
		],
	};
	
	return (
		<Line options={options} data={data} />
	)
}