import { initializeApp } from "firebase/app";
import { 
	getFirestore,
	query,
	orderBy,
	onSnapshot,
	collection,
	where,
	getDoc, 
	getDocs,
	setDoc,
	addDoc,
	updateDoc,
	doc, 
	arrayUnion
} from "firebase/firestore";
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	sendPasswordResetEmail,
	signOut,
	deleteUser,
	getUser,
	verifyPasswordResetCode
} from "firebase/auth";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
	// databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// const googleProvider = new GoogleAuthProvider();
// const signInWithGoogle = async () => {
// 	try {
// 		const res = await signInWithPopup(auth, googleProvider);
// 		const user = res.user;
// 		const q = query(collection(db, "users"), where("uid", "==", user.uid));
// 		const docs = await getDocs(q);
// 		if (docs.docs.length === 0) {
// 		await addDoc(collection(db, "users"), {
// 			uid: user.uid,
// 			name: user.displayName,
// 			authProvider: "google",
// 			email: user.email,
// 		});
// 		}
// 	} catch (err) {
// 		console.error(err);
// 		alert(err.message);
// 	}
// };
// const logInWithEmailAndPassword = async (email, password) => {
// 	try {
// 		await signInWithEmailAndPassword(auth, email, password);
// 	} catch (err) {
// 		console.error(err);
// 		alert(err.message);
// 	}
// };
// const registerWithEmailAndPassword = async (name, email, password) => {
// 	try {
// 		const res = await createUserWithEmailAndPassword(auth, email, password);
// 		const user = res.user;
// 		await addDoc(collection(db, "users"), {
// 		uid: user.uid,
// 		name,
// 		authProvider: "local",
// 		email,
// 		});
// 	} catch (err) {
// 		console.error(err);
// 		alert(err.message);
// 	}
// };
// const sendPasswordReset = async (email) => {
// 	try {
// 		await sendPasswordResetEmail(auth, email);
// 		alert("Password reset link sent!");
// 	} catch (err) {
// 		console.error(err);
// 		alert(err.message);
// 	}
// };

	async function getUserObject(uid) {
		return true;//await getUser(uid)
	}
	
	async function updateUserDoc(uid, name, email) {
		// return addDoc(collection(db, "users"), {
		// 	uid: currentUser.uid,
		// 	name,
		// 	authProvider: "local",
		// 	email: currentUser.email,
		// })
		try {
			await updateDoc(doc(db, "users", uid), {
				name, email
			})
			// await updateDoc(collection(db, "users"), uid, {
			// 	name,
			// 	email,
			// });
		} catch (err) {
			console.error(err);
		}
		// return collection(db, "users").where("uid", "==", uid).update({name, email})
	}
	
// const logout = () => {
// 	console.log('logout');
// 	console.log('auth', auth);
// 	signOut(auth);
// 	console.log('auth', auth);
// };
export {
	auth,
	db,
	// signInWithGoogle,
	// sendPasswordReset,
	// logInWithEmailAndPassword,
	// registerWithEmailAndPassword,
	// logout,
	
	signOut,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	sendPasswordResetEmail,
	updateUserDoc,
	setDoc,
	doc,
	deleteUser,
	getUserObject,
	verifyPasswordResetCode
};