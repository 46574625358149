import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { collection, onSnapshot, getDoc, doc } from "firebase/firestore";
import { GoogleMap, LoadScript, useGoogleMap, Marker, Circle } from '@react-google-maps/api';
import  { geohashForLocation, geohashQueryBounds, distanceBetween } from "geofire-common";
import { debounce } from "../../utils/functions";


const Test = () => {
	const [center, setCenter] = useState({ lat: 32.8959058, lng: -96.6754921 });
	const [mapRef, setMapRef] = useState(null);
	const [points, setPoints] = useState([
		[32.89954543142231, -96.6655347203487],
		[32.89460719444847, -96.69403298397845],
		[32.887182360703044, -96.70021134684931],
		[32.87860674639802, -96.69282802989052],
		[32.87882441551413, -96.67759464683834],
		[32.889094983649535, -96.67690834279213],
		[32.89788776579242, -96.66639330026078],
		[32.90149005043853, -96.65252892457012],
		[32.892153759773755, -96.6409428475039],
		[32.880080105382596, -96.63850097451139],
		[32.874029458186584, -96.65386583081612],
		[32.873634660283294, -96.6830867628433],
		[32.902031455941305, -96.6868225542626],
		[32.90916558371472, -96.69729674286513],
		[32.909274534748356, -96.66875261559905],
		[32.90923520246322, -96.711419602757],
		[32.91528983395504, -96.66583531918116],
		[32.915791032762925, -96.64540469351805],
		[32.91583058806384, -96.68295992876719],
		[32.887472928359294, -96.65691047368603],
		[32.89882226333262, -96.71493486659406],
		[32.875399338280936, -96.71201283214846],
		[32.866389317235125, -96.6945901522508]
	]);

	const handleMapLoaded = (map) => {
		setMapRef(map);
	}

	const setMapCenter = (c) => {
		if( c.lat!=center.lat || c.lng!=center.lng )
			setCenter(c);
	}

	const handleBoundsChanged = () => {
		console.log('%chandleBoundsChanged called!', 'color:red', center);
		if( mapRef ) {

			// const bounds = geohashQueryBounds([
			// 	mapRef.getCenter().lat(),
			// 	mapRef.getCenter().lng()
			// ], 1 * 1000);

			// setQueryBounds(bounds);
			setMapCenter( {
				lat: mapRef.getCenter().lat(),
				lng: mapRef.getCenter().lng()
			} );
		}
	}

	return (
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
			<GoogleMap
				mapContainerStyle={{
					position: 'relative',
					width: '100%',
					height: '80vh'
				}}
				center={ center }
				zoom={14}
				onLoad={map => handleMapLoaded(map)}
				onBoundsChanged={ debounce(handleBoundsChanged, 700) }
				// onDragEnd={ debounce(handleBoundsChanged, 700) }
				// onZoomChanged={ debounce(handleBoundsChanged, 700) }
			>
				{mapRef && points.map((p, k, index) => (
					<Marker
						key={k}
						title={`${k}`}
						position={{
							lat: p[0],
							lng: p[1],
						}}
						icon={{
							path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759 c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713 v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336 h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z",
							fillColor: 'red' ,
							fillOpacity: 1,
							strokeWeight: 0.5,
							strokeColor: "#000000",
							rotation: p.direction,
							origin: new window.google.maps.Point(0, 0),
							anchor: new window.google.maps.Point(25, 25),
						}}
					>
					</Marker>				
				))}
				<Circle
					center={ center }
					options={{
						strokeColor: '#FF0000',
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillColor: '#FF0000',
						fillOpacity: 0.35,
						visible: true,
						radius: 1000
					}}
				/>
			</GoogleMap>
		</LoadScript>
)
}

export default Test;