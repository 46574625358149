import React from "react"
// import Signup from "./authentication/signup"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Dashboard from "./Dashboard"
import Live from "./live/Live"
import MapCCID from "./live/MapCCID"
import Vehicles from "./Vehicles"
import Customers from "./Customers"
import Tickets from "./tickets/index"
import Users from "./Users"

import Login from "./authentication/login"
import PrivateRoute from "./authentication/PrivateRoute"
// import PrivateOutlet from "./authentication/PrivateRoute"
import ForgotPassword from "./authentication/forgotPassword"
import ResetPassword from "./authentication/resetPassword"
import Profile from "./authentication/profile"
// import Login2 from "../Login2"
import AddUser from "./users/AddUser"
import ImportData from "./ImportData"
import Vehicle from "./vehicles/Vehicle"
import Test from "./live/test"

function App() {
	return (
		<Router>
			<AuthProvider>
				<Routes>
					<Route path="/login" element={<Login />} />
					{/* <Route path="/login2" element={<Login2 />} /> */}
					{/* <Route path="/signup" element={<Signup />} /> */}
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					
					<Route exact path='/' element={<PrivateRoute><Dashboard/></PrivateRoute>} />
					<Route exact path='/profile' element={<PrivateRoute><Profile/></PrivateRoute>} />
					{/*
					<Route path="/" element={<PrivateOutlet />}>
						<Route path="" element={<Dashboard/>} />
					</Route>
					<Route path="/update-profile" element={<PrivateOutlet />}>
						<Route path="" element={<UpdateProfile/>} />
					</Route>
					*/}


					<Route path="/map" element={<PrivateRoute><Live /></PrivateRoute>} />
					<Route path="/mapCCID" element={<PrivateRoute><MapCCID /></PrivateRoute>} />
					<Route path="/map/:vin" element={<PrivateRoute><Live /></PrivateRoute>} />
					<Route path='/vehicles' element={<PrivateRoute><Vehicles /></PrivateRoute>} />
					<Route path='/vehicles/:vin' element={<PrivateRoute><Vehicle /></PrivateRoute>} />
					<Route path='/customers' element={<PrivateRoute><Customers /></PrivateRoute>} />
					<Route path='/customers/:customerId' element={<PrivateRoute><Customers /></PrivateRoute>} />
					<Route path='/tickets' element={<PrivateRoute><Tickets /></PrivateRoute>} />
					<Route path='/tickets/:vin' element={<PrivateRoute><Tickets /></PrivateRoute>} />
					<Route path='/users' element={<PrivateRoute><Users status={true} /></PrivateRoute>} />
					<Route path='/users/add' element={<PrivateRoute><AddUser /></PrivateRoute>} />
					<Route path='import' element={<PrivateRoute><ImportData /></PrivateRoute>} />

					<Route path='/test' element={<Test />} />
					{/* <Route path='/users/add/:userID' element={<PrivateRoute><User_add /></PrivateRoute>} /> */}

					{/* <Route path="/test" element={<Test />} /> */}
				</Routes>
			</AuthProvider>
		</Router>
	)
}

export default App