import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import CenteredContainer from "./CenteredContainer"

export default function Profile() {
	const displayNameRef = useRef()
	const emailRef = useRef()
	const passwordRef = useRef()
	const passwordConfirmRef = useRef()
	const { currentUser, updatePassword, updateEmail, updateProfileDetails } = useAuth()
	const [error, setError] = useState("")
	const [updateMessage, setUpdateMessage] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	function handleSubmit(e) {
		e.preventDefault()
		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Passwords do not match")
		}

		const promises = []
		setLoading(true)
		setUpdateMessage("")
		setError("")

		if (emailRef.current.value !== currentUser.email) {
			promises.push( updateEmail(emailRef.current.value) )
		}
		if (passwordRef.current.value) {
			promises.push( updatePassword(passwordRef.current.value) )
		}

		if (displayNameRef.current.value !== currentUser.displayName || emailRef.current.value !== currentUser.email) {
			promises.push( updateProfileDetails(currentUser.uid, displayNameRef.current.value, emailRef.current.value) )
		}

		Promise.all(promises)
			.then(() => {
				setUpdateMessage("Profile updated successfully")
			})
			.catch(() => {
				setError("Failed to update account")
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<>
			<Card>
				<Card.Body>
					<h2 className="text-center mb-4">Update Profile</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					{updateMessage && <Alert variant="success">{updateMessage}</Alert>}
					<Form onSubmit={handleSubmit}>
					<Form.Group id="displayName">
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							ref={displayNameRef}
							required
							defaultValue={currentUser.displayName}
						/>
					</Form.Group>
					<Form.Group id="email">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="email"
							ref={emailRef}
							required
							defaultValue={currentUser.email}
						/>
					</Form.Group>
					<Form.Group id="password">
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							ref={passwordRef}
							placeholder="Leave blank to keep the same"
						/>
					</Form.Group>
					<Form.Group id="password-confirm">
						<Form.Label>Password Confirmation</Form.Label>
						<Form.Control
							type="password"
							ref={passwordConfirmRef}
							placeholder="Leave blank to keep the same"
						/>
					</Form.Group>
					<Button disabled={loading} className="w-100" type="submit">
						Update
					</Button>
					</Form>
				</Card.Body>
			</Card>
		</>
	)
}