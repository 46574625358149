import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from 'react-router-dom';
import { auth, db } from './../firebase';
import { doc, onSnapshot } from "firebase/firestore"

import { Container, Navbar, Button } from 'react-bootstrap';
import { Power } from 'react-bootstrap-icons';
import Sidebar from './Sidebar';

import '../styles/dashboard.css';


const Template = ({ children }) => {
	const [user] = useAuthState(auth);
	const [userDetails, setUserDetails] = useState({})

	useEffect(() => {
		if (user) {
			const unsubscribe = onSnapshot(doc(db, "users", user.uid), (snapshot) => {
				setUserDetails( snapshot.data() );
			});
			return () => unsubscribe();
		}
	}
	, [user]);


	const logout = () => {
		auth.signOut();
	}


	return (
		<div className='main'>
			<Navbar variant="dark" sticky="top">
				<Container fluid>
					<Navbar.Brand href="#home">
						<img
							alt=""
							src="/car.png"
							width="30"
							height="30"
							className="d-inline-block align-top"
						/>{' '}
						Massimo UTV Fleet
					</Navbar.Brand>
					<Navbar.Toggle />
					<Navbar.Collapse className="justify-content-end">
						{user && 
							<Navbar.Text>
								Logged in as <a href="/profile">{userDetails?.name}</a>
								<Button style={{margin:'0 .5rem'}} onClick={logout} variant="outline-warning" size='sm'>Logout</Button>
							</Navbar.Text>
						}
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<div className='contents'>
				<div className='sidebarCol'>
					<Sidebar />
				</div>
				<div className='contentCol'>
					{children}
				</div>
			</div>
		</div>
	);
}

export default Template;