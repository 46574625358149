import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { collection, onSnapshot, query, where, updateDoc, doc, orderBy, limit, startAfter, limitToLast, endBefore, Timestamp } from "firebase/firestore"
import { Row, Card, Form, Button, Spinner } from "react-bootstrap";
import { CalendarCheck, Circle, PersonCheck } from 'react-bootstrap-icons';


const Admin = () => {

	const [user] = useAuthState(auth);
	const [userDetails, setUserDetails] = useState();

	const [users, setUsers] = useState();
	const [tickets, setTickets] = useState()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(true)
	const [assignLoading, setAssignLoading] = useState(false)
	const [assigning, setAssigning] = useState(false)
	const [responsing, setResponsing] = useState(false)
	const [responseLoading, setResponseLoading] = useState(false)

	// Pagination state variables
	const [lastVisible, setLastVisible] = useState();
	const [firstVisible, setFirstVisible] = useState();
	const [limitPerPage, setLimitPerPage] = useState( 10 );
	const [pageNumber, setPageNumber] = useState(0);
	const [isLastPage, setIsLastPage] = useState(false);

	const [statusFilter, setStatusFilter] = useState( Number(process.env.REACT_APP_TICKET_STATUS_NEW) )

	const status = []
	status[process.env.REACT_APP_TICKET_STATUS_NEW] = 'New'
	status[process.env.REACT_APP_TICKET_STATUS_IN_PROGRESS] = 'In Progress'
	status[process.env.REACT_APP_TICKET_STATUS_COMPLETED] = 'Completed'
	status[process.env.REACT_APP_TICKET_STATUS_ARCHIVED] = 'Archived'

	useEffect(() => {
		const unsubscribe = onSnapshot(doc(db, "users", user.uid), (snapshot) => {
			setUserDetails( snapshot.data() );
		});
		return () => unsubscribe();
	}, [user])

	useEffect(() => {
		if( !userDetails ) return;

		// get list of users for assigning action
		onSnapshot(query( collection(db, "users"), where('rule', '==', 'support') ), (snapshot) => {
			setUsers( snapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name})) );
		});

		// get list of all tickets for admin user rule
		const unsubscribe = onSnapshot(query( collection(db, "tickets"), orderBy('submitted_at', 'desc'), where('status', '==', statusFilter), limit( limitPerPage ) ), (snapshot) => {
			if(snapshot.size) {
				setTickets(snapshot.docs.map(
					doc => ({ id:doc.id, ...doc.data() })
				));
				setLoading(false)

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( 1 );

				if(snapshot.size < limitPerPage) {
					setIsLastPage(true);
				}
			} else {
				setIsLastPage(true);
			}
		});
		return () => unsubscribe()
	}, [userDetails, statusFilter])

	const handleNextPage = () => {
		const unsubscribe = onSnapshot(query(collection(db, `tickets`), orderBy('submitted_at', 'desc'), where('status', '==', statusFilter), limit( limitPerPage ), startAfter(lastVisible)), (snapshot) => {
			if(snapshot.size) {
				setTickets(snapshot.docs.map(
					doc => ({ id:doc.id, ...doc.data() })
				));

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( pageNumber+1 );

				if(snapshot.size < limitPerPage) {
					setIsLastPage(true);
				}
			} else {
				setIsLastPage(true);
			}
		});
		return () => unsubscribe();
	}

	const handlePreviousPage = () => {
		if (pageNumber > 1) {
			const unsubscribe = onSnapshot(query(collection(db, `tickets`), orderBy('submitted_at', 'desc'), where('status', '==', statusFilter), limitToLast( limitPerPage ), endBefore(firstVisible)), (snapshot) => {
				setTickets(snapshot.docs.map(
					doc => ({ id:doc.id, ...doc.data() })
				));

				// Get the first & last visible document
				setFirstVisible( snapshot.docs[0] );
				setLastVisible( snapshot.docs[ snapshot.size-1 ] );
				setPageNumber( pageNumber-1 );
				// TODO: check how can I remove this line
				setIsLastPage(false);
			});
			return () => unsubscribe();
		}
	}

	const handleShowAssignBox = (ticketId) => {
		setAssigning(ticketId)
	}

	const handleAssigning = (e) => {
		const id = e.value;
		const name = e.options[e.selectedIndex].text

		setAssigning(false)
		setAssignLoading(true)
		updateDoc(doc(db, "tickets", assigning), {
			assigned_to: {
				id,
				name
			},
			status: Number( process.env.REACT_APP_TICKET_STATUS_IN_PROGRESS )
		}).then(() => {
			setAssignLoading(false)
		}).catch(error => {
			setAssignLoading(false)
		})
	}

	const AssigningSelectBox = () => {
		return (
			<>
				{assignLoading && <Spinner animation="border" size='sm' variant="primary" />}
				<select id='assignSelectBox'>
					<option value="">Select a user</option>
					{users.map(user =>
						<option key={user.id} value={user.id}>{user.name}</option>
					)}
				</select>
				<Button onClick={() => handleAssigning(document.getElementById('assignSelectBox'))} size="sm" variant="outline-secondary">Set</Button>
			</>
		)
	}


	const handleShowResponse = (ticketId) => {
		setResponsing(ticketId)
	}

	const handleSubmitResponse = (e) => {
		e.preventDefault();
		if( window.confirm("Are you sure you want to submit this response to customer?") ) {

			setResponseLoading(true)
			updateDoc(doc(db, "tickets", responsing), {
				response: e.target.responseBox.value,
				responded_at: Timestamp.now(),
				status: Number( process.env.REACT_APP_TICKET_STATUS_COMPLETED ),
				assigned_to: {
					id: user.uid,
					name: userDetails.name,
				}
			}).then(() => {
				setResponseLoading(false)
				setResponsing(false)
			}).catch(error => {
				setResponseLoading(false)
			})
		}
	}

	const handleCharCount = (e) => {
		const charCount = e.target.value.length;
		const charLimit = e.target.getAttribute("maxlength");
		const charRemaining = charLimit - charCount;
		document.getElementById("charCount").innerHTML = charRemaining;
	}

	const ResponseBox = () => {
		let minLength = 100;
		let maxLength = 3000;

		return (
			<div className='responseBox'>
				<Form onSubmit={handleSubmitResponse}>
					<Form.Control
						name='responseBox'
						required
						minLength={minLength}
						maxLength={maxLength}
						onChange={handleCharCount}
						as="textarea"
						placeholder="Write down your response to this ticket here ..."
					/>
					<span className='charCount'>Minimum 100 Chars, Remaining <span id='charCount'>{maxLength}</span></span>
					{responseLoading && <Spinner animation="border" size='sm' variant="primary" />}&nbsp;
					<Button type="submit" size="sm" variant="outline-primary">Submit Response</Button>&nbsp;
					<Button onClick={() => setResponsing(false)} size="sm" variant="outline-secondary">Cancel</Button>
				</Form>
			</div>
		)
	}

	const handleToggleResponse = (e) => {
		let responseDiv = e.target.previousSibling

		if( responseDiv.classList.contains('hide') ) {
			e.target.previousSibling.classList.remove('hide')
			e.target.innerHTML = 'Hide Response'

		} else {
			e.target.previousSibling.classList.add('hide')
			e.target.innerHTML = 'Show Response'
		}
	}

	const handleArchive = (ticketId) => {
		if( window.confirm("Are you sure you want to archive this ticket?") ) {
			updateDoc(doc(db, "tickets", ticketId), {
				status: Number( process.env.REACT_APP_TICKET_STATUS_ARCHIVED )
			})
		}
	}

	const handleSetFilter = (status) => {
		setStatusFilter( Number(status) )
	}


	return (
		<div className='tickets'>
			<div className='titleBar'>
				<h3>Tickets Management <i style={{fontSize:'14px'}}>(page {pageNumber})</i></h3>
				<div>
					<div>
						<Button
							size='sm'
							variant={statusFilter==process.env.REACT_APP_TICKET_STATUS_NEW ? `success` : `outline-secondary`}
							onClick={() => handleSetFilter(process.env.REACT_APP_TICKET_STATUS_NEW)}
						>New</Button>
					</div>
					<div>
						<Button
							size='sm'
							variant={statusFilter==process.env.REACT_APP_TICKET_STATUS_IN_PROGRESS ? `success` : `outline-secondary`}
							onClick={() => handleSetFilter(process.env.REACT_APP_TICKET_STATUS_IN_PROGRESS)}
						>In Process</Button>
					</div>
					<div>
						<Button
							size='sm'
							variant={statusFilter==process.env.REACT_APP_TICKET_STATUS_COMPLETED ? `success` : `outline-secondary`}
							onClick={() => handleSetFilter(process.env.REACT_APP_TICKET_STATUS_COMPLETED)}
						>Completed</Button>
					</div>
					<div>
						<Button
							size='sm'
							variant={statusFilter==process.env.REACT_APP_TICKET_STATUS_ARCHIVED ? `success` : `outline-secondary`}
							onClick={() => handleSetFilter(process.env.REACT_APP_TICKET_STATUS_ARCHIVED)}
						>Archived</Button>
					</div>
				</div>
			</div>
			{tickets && tickets.length>0 ? tickets.map(doc =>
				<div key={doc.id}><Card className='bg-light'>
					<Card.Body>
						<Card.Title>{doc.subject} <span className='ticketId'>Ticket ID: {doc.id}</span></Card.Title>
						<Card.Text>{doc.description}</Card.Text>
						{responsing==doc.id && <ResponseBox />}
						{doc.response &&
							<>
								<Card.Text className='response hide'>
									{<p dangerouslySetInnerHTML={{ __html: doc.response.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />}
								</Card.Text>
								<Button style={{marginTop:'-6px'}} size='sm' variant="outline-secondary" onClick={handleToggleResponse}>Show Response</Button>
							</>
						}
					</Card.Body>
					<Card.Footer>
						<ul className='details'>
							<li>{new Date(doc.submitted_at.seconds * 1000).toLocaleDateString("en-US")}</li>
							<li><Link to={`/vehicles/${doc.vin}`}>{doc.model}</Link></li>
							<li><Link to={`/customers/${doc.customer.id}`}>{doc.customer.name}</Link></li>
							<li> <span className={`status${doc.status}`}>{ status[ doc.status ] }</span> &nbsp; {doc.status==process.env.REACT_APP_TICKET_STATUS_COMPLETED && <Button onClick={() => handleArchive(doc.id)} size='sm' variant="outline-secondary">Archive It</Button>}</li>
							<li>{
								doc.assigned_to?.id
									? <>Assigned to <b>{doc.assigned_to.name}</b></>
									: <>Not Assigned {
										assigning==doc.id
											? <AssigningSelectBox />
											: <Button size='sm' variant="outline-secondary" onClick={() => handleShowAssignBox(doc.id)}>Assign It</Button>
										}</>
								}</li>
							{!doc.response &&
								<li>
									<Button size='sm' variant="outline-secondary" onClick={() => handleShowResponse(doc.id)}>Make Response</Button>
								</li>
							}
						</ul>
					</Card.Footer>
				</Card><br /></div>
			) : 
				loading
					? <div className="loading"><div>
						<Spinner animation="border" />
						<p>Loading Tickets</p>
					</div></div>
					: <p style={{padding:"30px"}}><b>There is no tickets data.</b></p>
			}
			<div className='pagination'>
				<Button size="md" onClick={handlePreviousPage} disabled={pageNumber>1 ? false : true}>Previous</Button>
				<span>Page {pageNumber}</span>
				<Button size="md" onClick={handleNextPage} disabled={isLastPage ? true : false}>Next</Button>
			</div>
		</div>
	)
}

export default Admin;