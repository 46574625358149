import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Voicemail, Phone, Mailbox  } from "react-bootstrap-icons";

const customerDetailsCard = (vehicle) => {
	return (
		<>
			<p>Full Name: <b>{vehicle.name && vehicle.name}</b></p>
			<p>Dealer: <b>{vehicle.dealer}</b></p>
			<p>Registered At: <b>{vehicle.registered_at && new Date(vehicle.registered_at.seconds * 1000).toLocaleString([], {dateStyle: 'full'}) }</b></p>
			<p><b><Voicemail /> {vehicle.email && vehicle.email}</b> &#9900; <b><Phone /> {vehicle.phone && vehicle.phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}</b></p>
			<p><b><Mailbox /> {vehicle.address && vehicle.address}</b></p>
		</>
	)
}

// render vehicle details on the left side of the map
const VehicleDetailsCard = ({ vehicle }) => {
	const gearList = {
		p: 'Parking',
		r: 'Reverse',
		n: 'Neutral',
		d: 'Driving'
	}

	return (
			<div className="body">
				<div style={{display:'flex', flexDirection:'column', gap:'20px', padding:'10px'}}>
					<div style={{display:'flex', flexDirection:'row'}}>
						<div>{vehicle && <img src={`/imgs/vehicles/${vehicle.model}.jpg`} style={{width:'140px', height:'auto', margin:'0 10px 10px 0'}} />}</div>
						<div>
							<p><b style={{fontSize:'20px', padding:'0 5px 0 0'}}>{vehicle.model}</b> &#9900; <span style={{fontSize:'16px', padding:'0 0 0 5px'}} className={`gear ${vehicle.location.gear}`}>On {gearList[vehicle.location.gear]}</span></p>
							<p>VIN: <b>{vehicle.id}</b></p>
							{vehicle?.imei && <p>IMEI: <b>{vehicle.imei}</b></p>}
							<p>Color: <b>{vehicle?.color && vehicle.color}</b></p>
							<p>Mileage: <b>{vehicle.location.mileage} Miles</b></p>
							<p>Location: <b>{vehicle.location.location.latitude.toFixed(5)}, {vehicle.location.location.longitude.toFixed(5)}</b></p>
						</div>
					</div>
					<div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
						<div>
							<h4>Customer Information</h4>
							{ (!vehicle.customer_id || vehicle.customer_id=='')
								? <p>Vehicle not registered yet!</p>
								: customerDetailsCard(vehicle)
							}
						</div>
						<div>
							<h4>Vehicle Technical Information</h4>
							<div style={{display:'flex', flexDirection:'row', flexBasis:0}}>
								<div style={{flex:'1 1 0px'}}>
									<p>Trips Tracked: <b>{vehicle?.trips ? <Link to={`/vehicles/${vehicle.id}`}>{vehicle.trips} Trips</Link> : 'No Trip!'}</b></p>
									{vehicle.can && <>
										<p>Battery Voltage: <b>{vehicle.can.battery && vehicle.can.battery} %</b></p>
										<p>Oil Level: <b>{vehicle.can.oil && vehicle.can.oil} %</b></p>
										<p>Rotating Speed: <b>{vehicle.can.rpm && vehicle.can.rpm} RPM</b></p>
										<p>Water Temp: <b>{vehicle.can.water && vehicle.can.water} °F</b></p>
									</>}
									<p>Gear: <b>On {vehicle.location.gear && gearList[vehicle.location.gear]}</b></p>
									<p>Speed: <b>{vehicle.location.speed && vehicle.location.speed.toFixed(2)} MPH</b></p>
								</div>
								{(vehicle.can && vehicle.can.lights) &&
									<div style={{flex:'1 1 0px'}} className="lights">
									<p>Low Beam: <b className={vehicle.can.lights[0]=='0' ? 'off' : 'on'}></b></p>
									<p>High Beam: <b className={vehicle.can.lights[1]=='0' ? 'off' : 'on'}></b></p>
									<p>Left Signal: <b className={vehicle.can.lights[2]=='0' ? 'off' : 'on'}></b></p>
									<p>Right Signal: <b className={vehicle.can.lights[3]=='0' ? 'off' : 'on'}></b></p>
									<p>Dome Light: <b className={vehicle.can.lights[4]=='0' ? 'off' : 'on'}></b></p>
									<p>Engine Light: <b className={vehicle.can.lights[5]=='0' ? 'off' : 'on'}></b></p>
									<p>Oil Pressure Light: <b className={vehicle.can.lights[6]=='0' ? 'off' : 'on'}></b></p>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				<div>
					<Button
						variant="secondary" size="sm"
						disabled={vehicle.trips>0 ? false : true}
						href={`/vehicles/${vehicle.id}`}>
							Vehicle Trips {vehicle?.trips && `(${vehicle?.trips})`}
					</Button>
					<Button
						variant="secondary" size="sm"
						disabled={vehicle.tickets>0 ? false : true}
						href={`/tickets/${vehicle.id}`}>
							Vehicle Tickets {vehicle?.tickets && `(${vehicle?.tickets})`}
					</Button>
				</div>
			</div>
	);
}

export default VehicleDetailsCard;