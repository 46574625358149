import React, { useEffect, useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useParams } from "react-router-dom"
import CenteredContainer from "./CenteredContainer"

const ResetPassword = (props) => {
	const newPasswordRef = useRef()
	const confirmPasswordRef = useRef()
	const { resetPassword, _verifyPasswordResetCode } = useAuth()
	const [error, setError] = useState("")
	const [message, setMessage] = useState("")
	const [loading, setLoading] = useState(false)

	const { oobCode } = useParams()
	useEffect(() => {
		_verifyPasswordResetCode(oobCode)
			.then(function(email) {
				console.log("verified:", email)
				// Display a "new password" form with the user's email address
			})
			.catch(function(e) {
				setError("URL is invalid! Go back to the forgot password page and try again.")
				console.log('_verifyPasswordResetCode', e, oobCode, props)
				// Invalid code
			})
	}, [oobCode])

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setMessage("")
			setError("")
			setLoading(true)
			await resetPassword(newPasswordRef.current.value)
			setMessage("Check your inbox for further instructions")
		} catch (e) {
			setError("Failed to reset password")
			console.error(e)
		}

		setLoading(false)
	}

	return (
		<CenteredContainer>
			<Card>
			<Card.Body>
				<h2 className="text-center mb-4">Set New Password</h2>
				{error && <Alert variant="danger">{error}</Alert>}
				{message && <Alert variant="success">{message}</Alert>}
				{!error && <><Form onSubmit={handleSubmit}>
				<Form.Group id="password">
						<Form.Label>New Password</Form.Label>
						<Form.Control type="password" ref={newPasswordRef} required />
					</Form.Group>
					<Form.Group id="password">
						<Form.Label>Confirm New Password</Form.Label>
						<Form.Control type="password" ref={confirmPasswordRef} required />
					</Form.Group>
					<br/>
					<Button disabled={loading} className="w-100" type="submit">Reset Password</Button>
				</Form>
				<div className="w-100 text-center mt-3">
					<Link to="/login">Login</Link>
				</div></>}
			</Card.Body>
			</Card>
		</CenteredContainer>
	)
}

export default ResetPassword