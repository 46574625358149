import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import { db } from './../../firebase';
import { serverTimestamp, collection, query, where, onSnapshot, doc, setDoc } from "firebase/firestore"
import { useAuth } from "../../contexts/AuthContext";


const AddUser = ({props}) => {
	const [loading, setLoading] = useState(false)
	const { currentUser, signup, resetPassword } = useAuth()

	const [error, setError] = useState("")
	const [success, setSuccess] = useState("")

	const handleAddUser = async (e) => {
		e.preventDefault();
		const { name, email, rule } = e.target;
		try {
			setError("");
			setLoading(true);

			await fetch( ( process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND_API_BASEURL_PROD : process.env.REACT_APP_BACKEND_API_BASEURL_DEV ) + "/backendadmin/users/create", {
				method: "POST",
				headers: {
					"Authorization": "Bearer " + process.env.REACT_APP_BACKEND_API_KEY,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					name: name.value,
					email: email.value
				}),

			}).then(async response => {
				const data = await response.json();
				if (response.status === 200) {

					// add user to database
					await setDoc(doc(db, "users", data.userId), {
							name: name.value,
							email: email.value,
							status: true,
							rule: rule.value,
							added_by_id: currentUser.uid,
							added_by_name: currentUser.displayName,
							created_at: serverTimestamp()
						}).then( async () => {
							
							// send user email with link to verify email
							await resetPassword( email.value );
							
							setSuccess("User added successfully, User will receive an email with instructions to activate account.");
							setLoading(false);
						})
		
				} else {
					setError(data?.message);
					setLoading(false);
				}
			})
		} catch (e) {
			setError("Failed to create an account");
			console.error(e);
			setLoading(false);
		}
	}


	return (
		<div>
			<h1>User Add</h1>
			<Col md={6}>
				{error && <Alert variant="danger">{error}</Alert>}
				{success && <Alert variant="success">{success}</Alert>}
				<Form onSubmit={handleAddUser}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label>Name</Form.Label>
						<Form.Control type="text" placeholder="Enter name" />
						<Form.Text className="text-muted">
							Put full name or nickname of the new user.
						</Form.Text>
					</Form.Group>
	
					<Form.Group className="mb-3" controlId="email">
						<Form.Label>Email Address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" />
					</Form.Group>

					<Form.Group className="mb-3" controlId="rule">
						<Form.Label>Rule</Form.Label>
						<Form.Select>
							<option>Select user rule</option>
							<option value="admin">Admin</option>
							<option value="analyzer">Analyzer</option>
							<option value="support">Support</option>
							<option value="dealer">Dealer</option>
							<option value="customer">Customer</option>
						</Form.Select>
					</Form.Group>

					<Button variant="primary" type="submit">Submit {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}</Button>
				</Form>
			</Col>
		</div>
	);
}

export default AddUser;