import React, { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from 'react-router-dom';
import { auth, db, deleteUser } from './../firebase';
import { collection, query, where, onSnapshot, doc, updateDoc } from "firebase/firestore"
import { Row, Col, Table, Button, Spinner } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext"


const Users = (props) => {

	// const [currentUser] = useAuthState(auth);
	const { currentUser, updateStatus } = useAuth()

	const [users, setUsers] = useState()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (currentUser) {
			const unsubscribe = onSnapshot(query(collection(db, "users")), (snapshot) => {
				setUsers(snapshot.docs.map(
					doc => ({ id:doc.id, ...doc.data() })
				));
			});
			return () => unsubscribe()
		}
	}
	, [currentUser])

	// handle disable/enable user doc and user auth with confirmation prompt
	const handleStatusChange = async (u) => {
		if (window.confirm("Are you sure you want to change this user's status?")) {

			try {
				setError("");
				setLoading(u.id);

				const apiURL = ( process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND_API_BASEURL_PROD : process.env.REACT_APP_BACKEND_API_BASEURL_DEV ) + `/backendadmin/users/` + ( u.status ? 'disable' : 'enable' ) + `/${u.id}`
				await fetch( apiURL, {
					method: "PUT",
					headers: {
						"Authorization": "Bearer " + process.env.REACT_APP_BACKEND_API_KEY,
						"Content-Type": "application/json",
					}

				}).then(async response => {
					const data = await response.json();
					if (response.status === 200) {
						
						// update user status in database
						await updateDoc(doc(db, "users", u.id), {
							status: !u.status
						}).then( async () => {
							setLoading(false);
						})

					} else {
						setError(data?.message);
						setLoading(false);
					}
				})

			} catch (e) {
				setError("Failed update user status!");
				console.error(e);
				setLoading(false);
			}
		}
	}


	return (
	<Row>
		<h3>Users List</h3>
		<Col md={10}><Button href="/users/add">Add User</Button></Col>
		<Col md={12}>
			<Table striped bordered={true} hover={true}>
				<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>Rule</th>
						<th>Added By</th>
						<th>Created At</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{users && users.map(doc =>
						<tr key={doc.id}>
							<td>{doc.name} {!doc?.added_by_id && '(root user)'}</td>
							<td>{doc.email}</td>
							<td>{doc.rule}</td>
							<td>{doc.added_by_id ? (<a>{doc.added_by_name}</a>) : "(root user)"}</td>
							<td>{doc.created_at && doc.created_at.toDate().toLocaleString()}</td>
							<td><Button onClick={() => handleStatusChange(doc)} size='sm' variant={doc.status == true ? "outline-success" : "outline-danger"}>{doc.status == true ? "Enabled" : "Disabled"} {loading==doc.id && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}</Button></td>
						</tr>
					)}
				</tbody>
			</Table>
		</Col>
	</Row>
	)
}

export default Users;