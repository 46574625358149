import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { auth, db } from "./../../firebase";
import { collection, onSnapshot, doc, query, limit, orderBy, startAt, endAt } from "firebase/firestore";
import  { geohashForLocation, geohashQueryBounds, distanceBetween } from "geofire-common";
import { Marker, Circle } from '@react-google-maps/api';
import InfoCard from "./InfoCard";



const Points = ({ center: centerObject, boundsRadiusInMeter, loadVehicleDetails }) => {
	const [user] = useAuthState(auth);
	const [vehicles, setVehicles] = useState([]);
	const params = useParams()
	const colors = {
		p: '#ffb21d',
		r: '#ef6149',
		n: '#ffb21d',
		d: '#28bd5a'
	}
	const [activeMarker, setActiveMarker] = useState(null);
	console.log('%cpoints.js - vehicles.length:', 'color:orange', vehicles.length)

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
			return;
		}
		setActiveMarker(marker);
	};

// TODO: check this link for making marker move
// http://jsfiddle.net/pmrotule/9tfq5sqc/8/
// https://spectrum.chat/react-google-maps/general/what-is-the-proper-use-of-onload-and-ondata-function~f05ec40b-359c-4cc7-85b8-766fc185f7d7
	// get points of all vehicles
	useEffect(() => {
		console.log('useEffect params.vin');

		// if props vin passed, get point of that specific vehicle
		if(params.vin) {
			let ref = doc(db, `points`, params.vin);
			const unsubscribe = onSnapshot( ref, (snapshot) => {
				setVehicles([{ id:snapshot.id, ...snapshot.data() }]);
				setActiveMarker(snapshot.id);
			});
			return () => unsubscribe();
		}
	}
	, [params.vin]);

	useEffect(() => {
		console.log('useEffect queryBounds', centerObject);

		// else get points of all vehicles
		if(!params.vin) {

			// get geohash bounds of center of map by specific radius in meters, so * 1000 to convert meters to meters
			// https://cloud.google.com/firestore/docs/solutions/geoqueries#web_1
			// TODO: check this: https://stackblitz.com/edit/geofire-common?file=index.js
			let centerArray = [centerObject.lat, centerObject.lng]
			let queryBounds = geohashQueryBounds(centerArray, boundsRadiusInMeter);


			// Each item in 'bounds' represents a startAt/endAt pair. We have to
			// issue a separate query for each pair. There can be up to 9 pairs of
			// bounds depending on overlap.
			let ref = collection(db, 'points');
			console.log("bounds:", queryBounds)
			let docs = [];
			setVehicles([]);
			queryBounds.map((b) => {
				// FIXME: get repeated vehicles
				onSnapshot(query(ref, orderBy('geohash'), startAt(b[0]), endAt(b[1])), (snapshot) => {
					if(!snapshot.empty) {
						snapshot.docs.forEach((doc) => {
							docs = docs.filter(d => d.id!==doc.id)

							// We have to filter out a few false positives due to GeoHash
							// accuracy, but most will match
							const distanceInKm = distanceBetween([doc.data().location._lat, doc.data().location._long], centerArray);
							const distanceInM = distanceInKm * 1000;
							if (distanceInM <= boundsRadiusInMeter) {
								docs.push({ id:doc.id, ...doc.data() });
							}

						});
						console.log("vehicles:", docs.length);
						setVehicles(docs);
					}
				})
			});


		
			// TODO: Limit points to something like 50 points for performance reasons
			// let ref = collection(db, `points`);
			// const unsubscribe = onSnapshot( query(ref, limit(50)), (snapshot) => {
			// 	setVehicles(snapshot.docs.map(
			// 		doc => ({ id:doc.id, ...doc.data() })
			// 	));
			// });
			// return () => unsubscribe();
		}

	}
	, [centerObject, boundsRadiusInMeter]);


	// FIXME: bounds is not working properly, make map zoom out!
	const googleMapLoadCallback = (map) => {
		const bounds = new window.google.maps.LatLngBounds();
		vehicles.forEach(vehicle => {
			bounds.extend(new window.google.maps.LatLng(vehicle.location.latitude, vehicle.location.longitude));
		});
		
		map.fitBounds(bounds);
	}

// TODO: create child component for each marker, so if marker updated, do not render entire markers!!!

	return (
		<div>
			{vehicles.length && vehicles.map((vehicle) => (
				<Marker
					// styles={{ transform:`rotate(${vehicle.direction}deg)` }}
					key={vehicle.id}
					title={`${vehicle.id} - ${vehicle.geohash}`}
					// name={vehicle.model}
					// animation='BOUNCE'
					position={{
						lat: vehicle.location.latitude,
						lng: vehicle.location.longitude,
					}}
					icon={{
						path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759 c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713 v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336 h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z",
						// vehicle on PARKING gear have grey color, on DRIVING mode orange color
						fillColor: ( colors[vehicle.gear] ) ,
						fillOpacity: 1,
						strokeWeight: 0.5,
						strokeColor: "#000000",
						rotation: vehicle.direction,
						// scale: 1,
		
						// url:"/car.png",
						// style:{ transform:`rotate(${vehicle.direction}deg)` },
						origin: new window.google.maps.Point(0, 0),
						anchor: new window.google.maps.Point(25, 25),
						// scaledSize: new window.google.maps.Size(32, 32)
					}}
					onClick={() => handleActiveMarker(vehicle.id)}
				>
					{activeMarker === vehicle.id && 
						<InfoCard onCloseClick={() => setActiveMarker(null)} position={{
							lat: vehicle.location.latitude,
							lng: vehicle.location.longitude,
						}} vehicle={vehicle} loadVehicleDetails={loadVehicleDetails} />
					}
				</Marker>
				))}

				<Circle
					center={ centerObject }
					options={{
						strokeColor: '#FF0000',
						strokeOpacity: 0.8,
						strokeWeight: 1,
						// fillColor: '#FF0000',
						fillOpacity: 0.1,
						visible: true,
						radius: boundsRadiusInMeter
						}}
				/>
		</div>
	);
}

export default Points