import React, { useState } from 'react';
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { Modal, Button } from "react-bootstrap";
import Map from '../../utils/map';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
 } from 'chart.js';
 import { Line } from 'react-chartjs-2';


const TripDetails = (props) => {

/*
	const renderRoutebyDirection = (directionsRenderer, directionsService) => {
		if(props.show) {
			const wayPoints = props.show.map(p =>({
				location: {lat: p.location.latitude, lng: p.location.longitude},
				stopover: false
			}))
			const origin = wayPoints.shift().location;
			const destination = wayPoints.pop().location;
	
			directionsService.route({
				origin: origin,
				destination: destination,
				// waypoints: wayPoints, // FIXME: too much points causes limit error in google maps api (max 25)
				travelMode: window.google.maps.TravelMode.DRIVING
			}).then((res) => {
				console.log("directionsRenderer", res);
				directionsRenderer.setDirections(res);
			});
		}
	}
*/

	const renderRoutebyPolyline = () => {
		let path = [];
		
		if(props.show) {
			props.show.forEach(p => {
				path.push({lat: p.location.latitude, lng: p.location.longitude})
			})
		}

		return path;
	}

	const getData4Chart = (valueIndex) => {

		// Check for array if have data or have the valueIndex
		if( typeof props.show === 'undefined' || props.show.length == 0 )
			return;

		// if ( typeof props.show[0][valueIndex] == 'undefined' )
			// return;
		
		let data = [];
		let chartMaxPoints = 30;

		// if data array length <= chartMaxPoints just return the array value that requested
		if( props.show.length <= chartMaxPoints ) {
			return props.show.map(p => p[valueIndex]);

		} else {

			let jump = props.show.length / chartMaxPoints;
			for(let i=0; i < props.show.length; i=i+jump) {
				props.show[ Math.round(i) ] && data.push( props.show[ Math.round(i) ][ valueIndex ] )
			}
		}

		return data;
	}

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Filler,
		Legend
	);

	const options = {
		responsive: true,
		plugins: {
		legend: {
			position: 'bottom',
		},
		title: {
			display: true,
			text: 'Altitude',
		},
		},
	};

	let speedValues = getData4Chart('altitude');
	const data = {
		labels: speedValues.map((p,i) => i+1),
		datasets: [
		{
			fill: true,
			label: 'Altitude in Meter',
			data: speedValues,
			borderColor: 'rgb(53, 162, 235)',
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
			tension:.4,
			pointRadius: 0,
			pointHoverRadius: 10
		},
		],
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Trip Details ({props.show.length} Points)
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Map id="myMap" options={{/* center: { lat: 32.907732, lng: -96.70848 }, zoom: 14 */}}
					// direction={renderRoutebyDirection}
					polyline={renderRoutebyPolyline}
				/>
				<Line options={options} data={data} />
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default TripDetails;