import React from "react"
import ReactDOM from "react-dom/client";
import App from "./components/App"
import "bootstrap/dist/css/bootstrap.min.css"
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot( document.getElementById("root") );
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/reset" element={<Reset />} />
      <Route exact path='/' element={<Dashboard />} />
      <Route path="/map" element={<Live />} />
      <Route path='/vehicles' element={<Vehicles />} />
      <Route path='/customers' element={<Customers />} />
      <Route path='/tickets' element={<Tickets />} />
      <Route path='/users' element={<Users />} />
      <Route path='/edit/:id' element={Edit} />
      <Route path='/create' element={Create} />
      <Route path='/show/:id' element={Show} />
    </Routes>
  </BrowserRouter>
);
*/
