import React, { useContext, useState, useEffect } from "react"
import {
	db,
	auth,
	signOut,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	sendPasswordResetEmail,
	updateUserDoc,
	getUserObject,
	verifyPasswordResetCode
} from "../firebase"


const AuthContext = React.createContext()

export function useAuth() {
	return useContext(AuthContext)
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState()
	const [loading, setLoading] = useState(true)

	async function signup(email, password, name) {
		try {
			const res = await createUserWithEmailAndPassword(auth, email, password);
			await updateProfile(auth.currentUser, { displayName: name });
			return res.user;
		} catch (err) {
			console.error(err);
			return false
		}
	}

	function login(email, password) {
		return signInWithEmailAndPassword(auth, email, password)
	}

	function logout() {
		return signOut(auth)
	}

	function resetPassword(email) {
		var actionCodeSettings = {
			// URL to redirect to after a user has set the password.
			url: ( process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASEURL_PROD : process.env.REACT_APP_BASEURL_DEV ) + '/profile'
		};
		// FIXME: sendpasswordResetEmail do not send reset link to email, maybe we need to use api to send reset password email because of firebase admin!
		return sendPasswordResetEmail(auth, email, actionCodeSettings)
	}

	function _verifyPasswordResetCode(code) {
		return verifyPasswordResetCode(code)
	}

	function updateEmail(email) {
		return currentUser.updateEmail(email)
	}

	function updatePassword(password) {
		return currentUser.updatePassword(password)
	}

	function updateProfileDetails(uid, name, email) {
		updateProfile(currentUser, { displayName: name }).then(() => {
			updateUserDoc(uid, name, email)
		})
	}

	async function updateStatus(uid, status) {
		const user = getUserObject(uid)
		user.updateProfile({
			disabled: status
		}).then(() => {
			return true;
		}).catch(err => {
			console.error(err);
			return false;
		})
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			setCurrentUser(user)
			setLoading(false)
		})

		return unsubscribe
	}, [])

	const value = {
		currentUser,
		login,
		signup,
		logout,
		resetPassword,
		updateEmail,
		updatePassword,
		updateProfileDetails,
		updateStatus,
		_verifyPasswordResetCode
	}

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	)
}
