import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, MarkerClusterer, Marker } from '@react-google-maps/api';
import locations from './ccid_data.json';

const MapCCID = () => {
	const [clustered, setClustered] = useState(true);
	let iconOptions = {
		// path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759 c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713 v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336 h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z",
		// fillColor: '#ffb21d' ,
		// fillOpacity: 1,
		// strokeWeight: 0.5,
		// strokeColor: "#000000",
		// rotation: 0,
		// scale: 1,

		url:"./imgs/m1.png",
		// style:{ transform:`rotate(${vehicle.direction}deg)` },
		origin: {x:0, y:0}, //new window.google.maps.Point(0, 0),
		anchor: {x:25, y:25}, //new window.google.maps.Point(25, 25),
		// scaledSize: new window.google.maps.Size(32, 32)
	}

	const options = {
		// so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
		// https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png
		imagePath: './imgs/m',
		maxZoom:14,
		minimumClusterSize:1
	}

	return (
		<div style={{display:'flex', flexDirection:'row', justifyItems:'stretch', margin:'-20px'}}>
		<div style={{flexBasis:0, flexGrow:999, display:'flex', flexDirection:'column', alignItems:'strech', height:'100%'}}>
			<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
				<GoogleMap
					mapContainerStyle={{
						position: 'relative',
						width: '100%',
						height: '80vh'
					}}
					center={{ lat: 40.460837, lng: -98.829846 }}
					zoom={5}
				>
					{clustered && <MarkerClusterer options={options}>
						{(clusterer) =>
							locations.map((d) => (
							<Marker key={d.ccid} position={d} clusterer={clusterer} />
							))
						}
					</MarkerClusterer>}
					{!clustered && locations.map((d) => (
						<Marker key={d.ccid} position={d} />
					))}
				</GoogleMap>
			</LoadScript>
			<div>
				<button onClick={() => setClustered(true)}>Clustered Markers</button>
				<button onClick={() => setClustered(false)}>All Markers</button>
			</div>
		</div>
		</div>
	)
}

export default MapCCID;