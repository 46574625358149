import React, { Component } from 'react';
import { render } from 'react-dom';

class Map extends Component {
	constructor(props) {
		super(props);
		this.onScriptLoad = this.onScriptLoad.bind(this)
	}

	onScriptLoad() {

		const map = new window.google.maps.Map(
			document.getElementById(this.props.id),
			this.props.options
		);

		const addMarker = (label, position, map) => {
			new window.google.maps.Marker({
				position,
				label,
				map
			});
		}

		
		// Direction prop
		if( this.props.direction ) {
			const directionsService = new window.google.maps.DirectionsService();
			const directionsRenderer = new window.google.maps.DirectionsRenderer();
			directionsRenderer.setMap(map)
			this.props.direction(directionsRenderer, directionsService)
		}


		// Polyline prop
		if( this.props.polyline ) {

			const path = this.props.polyline()
			const polyline = new window.google.maps.Polyline({
				path,
				strokeColor: '#1389fd',
				strokeOpacity: 1.0,
				strokeWeight: 3
			});
			polyline.setMap(map);

			// Add start and finish markers to the route
			addMarker('A', path[0], map)
			addMarker('B', path[path.length-1], map)

			// make the route to center of map
			const bounds = new window.google.maps.LatLngBounds();
			path.forEach(p => {
				bounds.extend(p);
			})
			map.fitBounds( bounds );
		}


		// Markers prop
		if( this.props.markers ) {
			const bounds = new window.google.maps.LatLngBounds();
			this.props.markers.forEach(marker => {
				addMarker('A', marker, map)
				bounds.extend( marker );
			})
			map.fitBounds( bounds );
		}
	}

	componentDidMount() {
		if (!window.google) {
			var s = document.createElement('script');
			s.type = 'text/javascript';
			s.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
			var x = document.getElementsByTagName('script')[0];
			x.parentNode.insertBefore(s, x);
			// Below is important. 
			//We cannot access google.maps until it's finished loading
			s.addEventListener('load', e => {
				this.onScriptLoad()
			})
		} else {
			this.onScriptLoad()
		}
	}

	render() {
		return (
			<div style={{ width: 750, height: 500 }} id={this.props.id} />
		);
	}
}

class Marker extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		console.log('Marker', this.props.point);
	}

	render() {
		return (
			<div>
				<div>{this.props.label}</div>
				<div>{this.props.position}</div>
			</div>
		);
	}
}

export default Map
export { Marker }